import React from "react";
import Layout from "../components/layout";
import HeaderTwo from "../components/header/header-two";
import StickyHeader from "../components/header/sticky-header";
import Footer from "../components/footer";
import DonationOptions from "../components/donation-options";

const HomeTwo = () => {
  return (
    <Layout pageTitle="Made You Love Tennis Foundation | Donate">
      <HeaderTwo />
      <StickyHeader extraClassName="stricky-header-two" />
      <DonationOptions />
      <Footer />
    </Layout>
  );
};

export default HomeTwo;
